import React from 'react';
import { Group, CONTAINER_SIZES, TypographyStylesProvider, createStyles } from '@aviationexam/core';
import { SEO } from 'components';

const useStyles = createStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: CONTAINER_SIZES.md,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    margin: '100px auto',
    [theme.fn.smallerThan('md')]: {
      marginTop: 60,
      marginBottom: 60,
    },
    [theme.fn.smallerThan('xs')]: {
      marginTop: 40,
      marginBottom: 40,
    },
  },
  typography: {
    '& h1, h2, h3, h4, h5, h6': {
      color: theme.colors.gray[8],
      fontWeight: 600,
    },
    '& h1, h2, h3': {
      fontWeight: 400,
    },
    '& h2': {
      fontSize: 26,
    },
    '& p': {
      textAlign: 'justify',
      color: theme.colors.gray[8],
    },
  },
  fontSizeSm: {
    fontSize: theme.fontSizes.md,
  },
}));

function GdprPage() {
  const { classes } = useStyles();
  return (
    <>
      <SEO title="GDPR" description="Aviationexam GDPR" lang="en" />
      <TypographyStylesProvider className={classes.typography}>
        <article className={classes.root}>
          <h1>
            <Group direction="column" position="center">
              <div>GDPR - Declaration of Personal Data Processing</div>
              <div>Aviationexam s.r.o.,</div>
            </Group>
          </h1>
          <p style={{ textAlign: 'center' }}>
            with registered office at Prague 5 - Stodulky, Kovarova 39/23, Postal code 155 00, the
            Czech Republic, Identification No. 27593886, registered in the Commercial register kept
            by Municipal Court in Prague, Section C, file No. 117629
          </p>

          <h2>The detailed description of the data subjects&apos; rights</h2>

          <p>
            All rights of data subjects are in accordance with Regulation (EU) 2016/679 of the
            European Parliament and of the Council on the protection of natural persons with regard
            to the processing of personal data and on the free movement of such data, and repealing
            Directive 95/46/EC (General Data Protection Regulation), (hereinafter referred to as
            &quot;GDPR&quot;).
          </p>

          <h3>1. Right of Access to Personal Data</h3>

          <p>
            You have the right to confirmation whether personal data is processed or not, and if so,
            you have access to information on the processing purposes, categories of personal data
            concerned, recipients or categories of recipients, the period of storage of personal
            data, information about your rights, the right to file a complaint to the Office for
            Personal Data Protection, information on the source of personal data, information on
            whether automated decision making and profiling, information and safeguards are in place
            in the case of transferring personal data to a third country or an international
            organization. You have the right to be provided with the copies of the processed
            personal data.
          </p>

          <p>
            According to Article 15 of the GDPR, the data subject has a right of access to personal
            data, which includes the right to obtain from the company Aviationexam s.r.o.
          </p>

          <p>
            <ul>
              <li>
                confirmation as to whether or not processes personal data concerning him or her,
              </li>
              <li>
                if personal data is processed, he or she has the right to access personal data and
                the following information:
                <ul>
                  <li>about the purposes of the processing;</li>
                  <li>the categories of personal data concerned;</li>
                  <li>the recipients to whom the personal data have been or will be disclosed;</li>
                  <li>
                    the envisaged period of processing (storage), and if it is not possible to
                    determine this period, the criteria used to determine that period;
                  </li>
                  <li>
                    the existence of the right to request from the Controller rectification or
                    erasure of personal data concerning the data subject or restriction of their
                    processing or to object to such processing;
                  </li>
                  <li>the right to lodge a complaint with a supervisory authority;</li>
                  <li>
                    where the personal data are not collected from the data subject, any available
                    information as to their source;
                  </li>
                  <li>the existence of automated decision-making, including profiling;</li>
                  <li>
                    about the appropriate safeguards when transferring data outside the EU, unless
                    the rights and freedoms of others are adversely affected, also a copy of
                    personal data.
                  </li>
                </ul>
              </li>
            </ul>
          </p>

          <p>
            For the case of repeated requests by the data subject, the Controller may charge a
            reasonable fee for a copy of personal data. The right to a confirmation of the
            processing of personal data and information may be invoked in writing at the address of
            the Controller&apos;s registered office.
          </p>

          <h3>2. Right to Correct Inaccurate Data</h3>

          <p>
            Do we process as the Controller your out-of-date or inaccurate personal data? Did you
            change your home address, for example? Please inform us and we will correct the personal
            data.
          </p>

          <p>
            According to Article 16 of the GDPR, the data subject shall have the right to have
            corrected inaccurate personal data which the controller process about him or her and
            which concern him or her. Taking into account the purposes of the processing, the data
            subject shall have the right to have incomplete personal data completed, namely also by
            means of providing a supplementary statement.
          </p>

          <p>
            We will carry out the correction without unnecessary delay, always with respect to the
            technical possibilities. The request for correction of personal data can be submitted on
            the phone + 420 270 005 114, by e-mail support@aviationexam.com or directly in writing
            to the address of the registered office of the company Aviationexam s.r.o, Prague 13 -
            Stodůlky, Petržílkova 2583/13, postcode 15800.
          </p>

          <h3>3. Right to Erasure</h3>

          <p>
            In some stated cases, we are required to delete your personal data on your instruction.
            However, each such request is subject to an individual evaluation, as even the
            controller has a duty or a legitimate interest to keep personal data. According to
            Article 17 of the GDPR, the data subject has the right to have erased personal data,
            which concern him or her, if:
            <ul>
              <li>
                the personal data are no longer needed in relation to the purposes for which they
                were collected or otherwise processed;
              </li>
              <li>
                the data subject withdraws consent on which the processing of personal data was
                based, and there is no other legal ground for the processing;
              </li>
              <li>
                the data subject objects to the processing pursuant to Article 21(1) of GDPR and
                there are no overriding legitimate grounds for the processing, or the data subject
                objects to the processing pursuant to Article 21(2) of GDPR;
              </li>
              <li>the personal data have been unlawfully processed;</li>
              <li>
                the personal data have to be erased for compliance with a legal obligation stated in
                the law of Union or Member State to which the controller is subject;
              </li>
              <li>
                the personal data have been collected in relation to the offer of information
                society services referred to in Article 8 (1) of GDPR.
              </li>
            </ul>
          </p>

          <p>
            The Controller shall not be obliged to delete personal data without undue delay, in
            particular where the processing of personal data is necessary to fulfill a legal
            obligation which requires processing by Union or Member State law to which the
            Controller is subject, or for the performance of a task carried out in the public
            interest or in the exercise of official authority vested in the Controller; or for for
            the establishment, exercise or defence of legal claims of the Controller.
          </p>

          <p>
            The Controller has the mechanisms set up to ensure automatic anonymization or deletion
            of personal data for the case they are no longer needed for the purpose for which they
            were processed.
          </p>

          <h3>4. Right to Restriction of Processing</h3>

          <p>
            According to Article 18 of the GDPR, the data subject has the right for the restriction
            of the processing until the resolution of the incentive, if he or she denies the
            accuracy of the personal data, the reasons for its processing, or if he or she objects
            to the processing, namely in writing to the address of the Controller&apos;s office.
          </p>

          <h3>
            5. Right to the Notification of Rectification, Erasure or Restriction of Processing
          </h3>

          <p>
            According to Article 19 of the GDPR, the data subject has the right to the notification
            by the Controller in the case of rectification, erasure or restriction of processing of
            personal data. If personal data are rectified or erased, we will notify individual
            recipients, except for the cases, when it is proven impossible or it requires
            unreasonable effort. Based on the request of the data subject, we can provide
            information about these recipients.
          </p>

          <h3>6. Right to Data Portability</h3>

          <p>
            If you wish to provide your personal data to another Controller, or another company, we
            will forward your personal data in the appropriate format to your designated entity,
            unless statutory or other significant obstacles will hinder us from doing so.
          </p>

          <p>
            According to Article 20 of the GDPR, the data subject shall have the right to data
            portability concerning him or her and which he or she has provided to the Controller, in
            a structured, commonly used and machine-readable format, and has the right to request
            the Controller to transmit those data to another controller.
          </p>

          <p>
            If the data subject provides us with personal data in connection with a service contract
            or based on the consent, and its processing is automated, he or she has the right to
            obtain from us such data in a structured, commonly used and machine-readable format. If
            technically feasible, the data can also be forwarded to your designated controller.
          </p>

          <p>
            In the event that the exercise of this right could lead to adverse affection of rights
            and freedoms of third parties, your application can not be fulfilled.
          </p>

          <h3>7. Right to Object to the Processing of Personal Data</h3>

          <p>
            If you would find out or even only believe that we carry out the processing of personal
            data in contrary to the protection of your private and personal life or in contradiction
            with the legal regulations, please contact us and request from us an explanation or
            removal of the established defective state.
          </p>

          <p>
            According to Article 21 of the GDPR, the data subject shall have the right to object to
            the processing of his or her personal data based on the ground of the legitimate
            interest of the Controller. In the case that the Controller does not prove that there
            exists a serious legitimate reason for processing that prevails over the interests or
            rights and freedoms of the data subjects, or for the establishment, exercise or defence
            of legal claims, the Controller terminates the processing based on the objection without
            undue delay.
          </p>

          <h3>8. Right to Revoke the Consent to the Processing of Personal Data</h3>

          <p>
            The consent to process personal data for marketing and business purposes may be revoked
            at any time. The revocation must be made by the explicit, comprehensible, and by
            particular expression of will, in the simplest way in your profile on the website of the
            Controller, on the web:{' '}
            <a href="https://aviationexam.com" target="_blank" rel="noreferrer">
              aviationexam.com
            </a>{' '}
            or on the phone + 420 270 005 114, by e-mail support@aviationexam.com or directly in
            writing to the address of the registered office of the company Aviationexam s.r.o,
            Prague 13 - Stodůlky, Petržílkova 2583/13, postcode 15800. The data processing from
            cookies can be precluded by setting up a webbrowser.
          </p>

          <h3>9. Automated individual decision-making, including profiling</h3>

          <p>You can also object to automated decision-making and profiling.</p>

          <p>
            The data subject has the right not to be the subject matter of any decision based solely
            on automated processing, including profiling, which would have for him or her legal
            effects or would affect him or her significantly by similar way. The Controller states
            that he does not perform automated decision-making without the impact of human
            considerations with legal effects on data subjects.
          </p>

          <h3>10. Right to contact the Office for Personal Data Protection</h3>

          <p>
            The data subject has the right to file an incentive or a complaint concerning the
            processing of personal data to the supervisory authority, namely the Office for Personal
            Data Protection, with the registered office at Pplk. Sochora 27, 170 00 Prague 7,
            website:{' '}
            <a href="https://www.uoou.cz/" target="_blank" rel="noreferrer">
              uoou.cz
            </a>
            .
          </p>

          <h3>11. Are my rights charged?</h3>

          <p>
            Individual rights can be claimed at the Controller, namely by calling on the phone + 420
            270 005 114, by e-mail support@aviationexam.com or directly in writing to the address of
            the registered office of the company Aviationexam s.r.o, Prague 13 - Stodůlky,
            Petržílkova 2583/13, postcode 15800.
          </p>

          <p>
            All communications and statements about your claimed rights are provided free of charge.
            However, if the application would be manifestly unfounded or excessive, in particular
            because it would be repeated, we are entitled to charge a reasonable fee taking into
            account the administrative costs connected with the providing of the requested
            information. In the case of a repeated claim for providing the copies of the processed
            personal data, we reserve the right to charge a reasonable fee for the administrative
            costs from this reason.
          </p>

          <h3>12. Are there any time-limits for the dispatch?</h3>

          <p>
            We will provide you with the comments, and eventually information, about the measures
            accepted as soon as possible, but not later than within one month. We are entitled to
            extend the deadline by two more months if necessary, with regard to the complexity and
            the number of applications. We would inform you about the extension, including reasons.
          </p>
        </article>
      </TypographyStylesProvider>
    </>
  );
}

export default GdprPage;
